<template>
  <div class="container">
    <div>
      <div v-if="!token">
        <Alert type="error">
          An error prompt
          <span slot="desc">
            This looks like a wrong link, please check if the link is correct.
          </span>
        </Alert>
      </div>
      <div v-else class="form-box">
        <div class="title">{{ email }} Register to Company</div>

        <div class="content">
          <div class="form">
            <Card>
              <Form ref="form1" :model="form1" :rules="rules1">
                <Row :gutter="20">
                  <i-col span="12">
                    <FormItem prop="first_name" label="FIRST NAME">
                      <Input v-model="form1.first_name" />
                    </FormItem>
                  </i-col>

                  <i-col span="12">
                    <FormItem prop="last_name" label="LAST NAME">
                      <Input v-model="form1.last_name"
                    /></FormItem>
                  </i-col>

                  <i-col span="12">
                    <FormItem prop="phone" label="PHONE NUMBER">
                      <Input v-model="form1.phone" />
                    </FormItem>
                  </i-col>

                  <i-col span="12">
                    <FormItem prop="password" label="PASSWORD">
                      <Input type="password" v-model="form1.password" />
                    </FormItem>
                  </i-col>

                  <i-col span="12">
                    <FormItem prop="re_password" label="RE-ENTER PASSWORD">
                      <Input type="password" v-model="form1.re_password"
                    /></FormItem>
                  </i-col>
                </Row>
              </Form>
            </Card>
          </div>
        </div>

        <div class="btns">
          <Button type="primary" :loading="loading" @click="registerMember"
            >Register</Button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
const { _registerMember, _queryAcceptMember } = api;

export default {
  computed: {
    ...mapState("user", ["timezone"])
  },
  created() {
    const token = this.$route.query.token;
    this.token = token;

    if (token) {
      _queryAcceptMember({
        token
      })
        .then(response => {
          const { data } = response;
          this.email = data.email;
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        });
    }
  },
  data() {
    return {
      loading: false,
      email: "",
      token: "",

      form1: {
        first_name: null,
        last_name: null,
        phone: null,
        password: null,
        re_password: null
      },
      rules1: {
        first_name: [
          {
            required: true,
            message: "Please fill"
          }
        ],
        last_name: [
          {
            required: true,
            message: "Please fill"
          }
        ],
        phone: [
          {
            required: true,
            message: "Please fill"
          }
        ],
        password: [
          {
            required: true,
            message: "Please fill"
          },
          {
            min: 6,
            message: "No less than 6 characters"
          }
        ],
        re_password: [
          {
            required: true,
            message: "Please fill"
          },
          {
            min: 6,
            message: "No less than 6 characters"
          },
          {
            message: "The confirmation password is not identical",
            validator: (rule, value) => {
              return value === this.form1.password;
            }
          }
        ]
      }
    };
  },
  methods: {
    registerMember() {
      this.$refs.form1.validate(valid => {
        if (valid) {
          this.loading = true;
          const form1 = JSON.parse(JSON.stringify(this.form1));
          delete form1.re_password;

          _registerMember({
            ...form1,
            timezone: this.timezone,
            token: this.token
          })
            .then(() => {
              // this.$router.push(
              //   `/user-action/register-success?email=${encodeURIComponent(
              //     this.email
              //   )}`
              // );
              this.$Message.success("Account registration succeeded");
              this.$router.push("/user-action/welcome-invite");
            })
            .catch(({ message }) => {
              this.$Message.error(message);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .step {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 50px;

    .item {
      padding: 3px;
      background: @secondary-color;
      border-radius: 20px;
      &.active {
        color: #fff;
        font-weight: bold;
        .inner {
          background: @primary-color;
        }
      }
      .inner {
        padding: 6px 10px;
        border-radius: 20px;
        background: @secondary-color;
      }
    }
    .divider {
      width: 20px;
      height: 6px;
      background: @secondary-color;
    }
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 20px;
      color: #464c5b;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .btn {
      width: 240px;
      margin-top: 10px;
    }
  }

  .form-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    .title {
      font-size: 20px;
      color: #464c5b;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .content {
      margin-bottom: 20px;
      .form {
        width: 600px;
      }
    }
    .btns {
      display: flex;
      align-self: flex-end;
    }
  }
}
</style>
